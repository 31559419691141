import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-7cf2f3d1"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "errorMessage" }
const _hoisted_2 = { class: "errorMessage" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_label = _resolveComponent("ion-label")!
  const _component_ion_input = _resolveComponent("ion-input")!
  const _component_ion_item = _resolveComponent("ion-item")!
  const _component_ion_button = _resolveComponent("ion-button")!
  const _component_ion_card = _resolveComponent("ion-card")!
  const _component_ion_col = _resolveComponent("ion-col")!
  const _component_ion_row = _resolveComponent("ion-row")!
  const _component_ion_grid = _resolveComponent("ion-grid")!
  const _component_ion_content = _resolveComponent("ion-content")!
  const _component_ion_page = _resolveComponent("ion-page")!

  return (_openBlock(), _createBlock(_component_ion_page, null, {
    default: _withCtx(() => [
      _createVNode(_component_ion_content, null, {
        default: _withCtx(() => [
          _createVNode(_component_ion_grid, null, {
            default: _withCtx(() => [
              _createVNode(_component_ion_row, { style: {"padding-top":"20%"} }, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_col, {
                    "size-md": "8",
                    "size-lg": "6",
                    "offset-md": "2",
                    "offset-lg": "3"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_ion_card, { class: "ion-padding" }, {
                        default: _withCtx(() => [
                          _createVNode(_component_ion_label, { style: {"margin":"24px"} }, {
                            default: _withCtx(() => [
                              _createTextVNode(" Please sign in with your Bazooka credentials. ")
                            ]),
                            _: 1
                          }),
                          _createVNode(_component_ion_item, null, {
                            default: _withCtx(() => [
                              _createVNode(_component_ion_label, { position: "floating" }, {
                                default: _withCtx(() => [
                                  _createTextVNode("Email")
                                ]),
                                _: 1
                              }),
                              _createVNode(_component_ion_input, {
                                modelValue: _ctx.email,
                                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.email) = $event))
                              }, null, 8, ["modelValue"]),
                              _createElementVNode("span", _hoisted_1, _toDisplayString(_ctx.emailError), 1)
                            ]),
                            _: 1
                          }),
                          _createVNode(_component_ion_item, { class: "ion-margin-bottom" }, {
                            default: _withCtx(() => [
                              _createVNode(_component_ion_label, { position: "floating" }, {
                                default: _withCtx(() => [
                                  _createTextVNode("Password")
                                ]),
                                _: 1
                              }),
                              _createVNode(_component_ion_input, {
                                modelValue: _ctx.password,
                                "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.password) = $event)),
                                type: "password"
                              }, null, 8, ["modelValue"]),
                              _createElementVNode("span", _hoisted_2, _toDisplayString(_ctx.passwordError), 1)
                            ]),
                            _: 1
                          }),
                          _createVNode(_component_ion_button, {
                            class: "ion-float-right",
                            style: {"margin":"12px"},
                            onClick: _ctx.submit,
                            color: "success"
                          }, {
                            default: _withCtx(() => [
                              _createTextVNode("Submit")
                            ]),
                            _: 1
                          }, 8, ["onClick"])
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}