
import { defineComponent, inject } from "vue";
import { agent } from "@/agent";
import {
  IonItem,
  IonPage,
  IonContent,
  IonCard,
  IonInput,
  IonLabel,
  IonButton,
  IonCol,
  IonRow,
  IonGrid
} from "@ionic/vue";
import { useStorage } from "@/composables/useStorage";
import { useRouter } from "vue-router";
import { useField, useForm } from "vee-validate";
import * as yup from "yup";
import { useFeature } from "@/composables/useFeature";

export default defineComponent({
  components: {
    IonItem,
    IonPage,
    IonContent,
    IonCard,
    IonInput,
    IonLabel,
    IonButton,
    IonCol,
    IonRow,
    IonGrid
  },
  setup() {
    const router = useRouter();
    const isMobile = inject("isMobile") as boolean;
    const { setUser } = useStorage();
    const { raiseToast } = useFeature();

    // Validation
    const schema = yup.object({
      email: yup
        .string()
        .email()
        .required(),
      password: yup.string().required()
    });

    const theForm = useForm({ validationSchema: schema });

    const { value: email, errorMessage: emailError } = useField("email");
    const { value: password, errorMessage: passwordError } = useField(
      "password"
    );

    const submit = async () => {
      const isValid = (await theForm.validate()).valid;
      if (!isValid) {
        return;
      }
      const { bazookaLogin } = agent();
      const res = await bazookaLogin(
        email.value as string,
        password.value as string
      );
      if (res && res.data !== 200) {
        const login = res.data;

        await setUser({
          firstName: login.user.firstName,
          lastName: login.user.lastName,
          email: login.user.email,
          isAdmin: login.user.isAdmin,
          publicId: login.user.publicId,
          expiresIn: login.expiresIn,
          token: login.token
        });

        await theForm.resetForm();

        if (isMobile) {
          router.push({ name: "Pin" });
        } else {
          router.push({ name: "New" });
        }
      } else {
        await raiseToast(
          "There was an problem logging you in. Please try again",
          "danger"
        );
      }
    };
    return {
      email,
      emailError,
      password,
      passwordError,
      isMobile,
      submit
    };
  }
});
